<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical"/>
          <a @click="$refs.createForm.handleUpdate(record, undefined)">
            <a-icon type="edit" />修改
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageTaskDeploy,listTaskDeploy, delTaskDeploy } from '@/api/platform/taskDeploy'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'TaskDeploy',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查詢参数
      queryParam: {
        type: null,
        integral: null,
        periodType: null,
        count: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '任务類型',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
          if (value == 1) {
            return "登录"
          }
          if (value == 2) {
            return "注册"
          }
          if (value == 3) {
            return "邀请好友注册"
          }
          if (value == 4) {
            return "观看視頻"
          }
          if (value == 5) {
            return "观看直播"
          }
          if (value == 6) {
            return "上传作品"
          }
          if (value == 7) {
            return "观看广告"
          }
        }
        },
        {
          title: '奖励米幣',
          dataIndex: 'integral',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'VIP奖励米幣',
          dataIndex: 'vipIntegral',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '奖励周期',
          dataIndex: 'periodType',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 1) {
              return "每日"
            }
            if (value == 2) {
              return "全局"
            }
          }
        },
        {
          title: '任务次数',
          dataIndex: 'count',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == -1) {
              return "不限"
            }
            return value
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查詢任务-配置列表 */
    getList () {
      this.loading = true
     pageTaskDeploy(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        type: undefined,
        integral: undefined,
        periodType: undefined,
        count: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 刪除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认刪除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delTaskDeploy(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '刪除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空刪除ids列表
          that.ids = []
        }
      })
    },
    /** 導出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认導出?',
        content: '此操作将導出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('platform/task-deploy/export', {
            ...that.queryParam
          }, `任务-配置_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
